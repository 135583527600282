import React from "react"
import { Container } from "reactstrap"
import { withAuthenticator } from "@aws-amplify/ui-react"

import Layout from "components/layout"

const Assessments = () => {
  return (
    <Layout>
      <Container className="pt-5"></Container>
    </Layout>
  )
}

export default withAuthenticator(Assessments)
